// Plain JavaScript version - no jQuery required

let whatsapp = "https://wa.me/6596886222?text=Appointment%20Request";
let callme = "tel:+6596886222";
let facebook = "https://www.facebook.com/voguehairworkshop";
let googlebiz = "https://goo.gl/maps/vPkeUKZucmVuLdzJA";

// Wait for DOM to be fully loaded before executing code
document.addEventListener('DOMContentLoaded', function() {
	//Modal Window functions
	function openModal() {
		const modal = document.querySelector('.modal');
		modal.style.display = 'flex';
		modal.style.opacity = '1';
		document.querySelector('html').classList.add('no-scroll');
		document.querySelector('body').classList.add('no-scroll');
	}

	function closeModal() {
		const modal = document.querySelector('.modal');
		modal.style.opacity = '0';
		setTimeout(() => {
			modal.style.display = 'none';
		}, 300); // Approximate time for fadeOut effect
		document.querySelector('html').classList.remove('no-scroll');
		document.querySelector('body').classList.remove('no-scroll');
	}

	// Set href attributes for social links
	document.querySelector('.emllac').setAttribute('href', callme);
	document.querySelector('.ppastahw').setAttribute('href', whatsapp);
	document.querySelector('.ssensiub').setAttribute('href', googlebiz);
	document.querySelector('.koobecaf').setAttribute('href', facebook);
	document.querySelector('.appt').setAttribute('href', whatsapp);

	// Add event listeners
	const modalTriggers = document.querySelectorAll("[data='modal']");
	modalTriggers.forEach(trigger => {
		trigger.addEventListener('click', function(e) {
			e.preventDefault();
			openModal();
		});
	});

	const closeButtons = document.querySelectorAll('.modal .modal-close');
	closeButtons.forEach(button => {
		button.addEventListener('click', closeModal);
	});

	// Add escape key listener
	document.addEventListener('keydown', function(event) {
		if (event.key === 'Escape') {
			closeModal();
		}
	});
}); // DOMContentLoaded end
